import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Section, Columns, Box } from 'react-bulma-components'

function Inspiration() {
  return (
    <Section className="has-background-grey-lighter is-medium">
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-8 has-text-centered">
            <h2 className="title pb-0 mb-3">Example Mobile Strategies</h2>
            <p className="pb-3 pt-0 mt-0">
              Take a look at the market leaders to see what sets them apart when
              it comes to developing mobile apps.
            </p>
          </Columns.Column>
        </Columns>
        <Columns className="is-centered">
          <Columns.Column className="is-6">
            <Box>
              <Columns className="is-vcentered">
                <Columns.Column className="is-2">
                  <StaticImage
                    src="https://www.datocms-assets.com/20955/1662711134-fender_guitars_logo.png?auto=compress&crop=bottom&dpr=0.24&fit=crop&fm=png&w=300"
                    alt="Fender mobile strategy"
                    placeholder="tracedSVG"
                  />
                </Columns.Column>
                <Columns.Column className="is-8">
                  <span className="is-size-7 has-text-grey has-text-uppercase">
                    HOW THEY WIN
                  </span>
                  <br />
                  <Link to="/mobile-strategy-inspirations/fender-educates-and-empowers">
                    Fender creates digital tools to attract, educate and empower
                    customers
                  </Link>
                </Columns.Column>
              </Columns>
            </Box>
          </Columns.Column>
          <Columns.Column className="is-6">
            <Box>
              <Columns className="is-vcentered">
                <Columns.Column className="is-2">
                  <StaticImage
                    src="https://www.datocms-assets.com/20955/1662932859-nike-logo.png?auto=compress&crop=bottom&dpr=0.24&fit=crop&fm=png&w=300"
                    alt="Nike mobile strategy"
                    placeholder="tracedSVG"
                  />
                </Columns.Column>
                <Columns.Column className="is-8">
                  <span className="is-size-7 has-text-grey has-text-uppercase">
                    HOW THEY WIN
                  </span>
                  <br />
                  <Link to="/mobile-strategy-inspirations/nike-engages-customers">
                    Nike creates apps to engage super-fans and delight consumers
                  </Link>
                </Columns.Column>
              </Columns>
            </Box>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default Inspiration
