import PropTypes from 'prop-types'
import React from 'react'
import { Section, Columns, Container } from 'react-bulma-components'

import ResourceCard from 'components/resource-card'
import Separator from 'components/separator'

function Resource({ resources }) {
  return (
    <Section>
      <Container className="is-widescreen">
        <Columns className="is-centered is-hidden-mobile">
          <Columns.Column className="is-12">
            <ResourceCard
              resource={
                resources.edges.filter(
                  (edge) => edge.node.isFeatured === true
                )[0].node
              }
              className="has-bg-orange"
              jumbo
            />
          </Columns.Column>
          <Columns.Column className="is-12">
            <ResourceCard
              resource={
                resources.edges.filter(
                  (edge) => edge.node.isFeatured === true
                )[1].node
              }
              className="has-bg-purple"
              jumbo
            />
          </Columns.Column>
          <Columns.Column className="mt-5 mb-3 is-8-widescreen is-10-desktop is-12-mobile has-text-centered">
            <h2 className="title">All Resources</h2>
          </Columns.Column>
        </Columns>
        <Columns className="is-centered is-4">
          {resources.edges.map(({ node: resource }) => (
            <Columns.Column
              className="is-4-desktop is-6-tablet"
              key={resource.slug}
            >
              <ResourceCard jumbo={false} resource={resource} />
            </Columns.Column>
          ))}
        </Columns>
      </Container>
    </Section>
  )
}
Resource.propTypes = {
  resources: PropTypes.object,
}
export default Resource
