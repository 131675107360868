import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import Testimonials from 'components/testimonials'
import BlogPost from 'components/blogPost'
import Clients from 'components/clients'
import Customers from 'components/customers'
import Objectives from 'components/objectives'
import AnnaQuote from 'components/annaQuote'

// Page Components
import HeroComponent from './components/hero'
import Inspiration from './components/inspiration'
import Resource from './components/resource'

function StrategyTookit({ data: { page, testimonials, posts, resources } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <HeroComponent page={page} />
      <Resource resources={resources} />
      <BlogPost posts={posts} />
      <Inspiration />
    </Layout>
  )
}

StrategyTookit.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrategyTookit
