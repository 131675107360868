/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta/Meta'

// Mobile Strategy Route
import StrategyTookit from 'routes/mobile-strategy-toolkit'

export default StrategyTookit

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query StrategyTookitQuery {
    page: datoCmsPage(slug: { eq: "mobile-strategy-toolkit" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
      featuredMedia {
        gatsbyImageData(
          imgixParams: { fm: "jpg", auto: "compress", maxW: 500 }
          placeholder: TRACED_SVG
        )
        alt
      }
    }
    testimonials: allDatoCmsClientTestimonial(limit: 4) {
      edges {
        node {
          id
          clientName
          clientRole
          content
          clientCompany
          isFlipped
          caseStudy {
            slug
          }
          clientLogo {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
          clientImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
        }
      }
    }
    posts: allDatoCmsArticle(
      limit: 6
      filter: {
        slug: {
          in: [
            "what-are-best-practices-when-building-apps"
            "why-your-app-isnt-getting-enough-downloads"
            "mobile-strategy"
            "why-our-customers-choose-product-over-project"
            "a-simple-way-to-increase-your-app-revenue"
            "gartner-reveals-what-digital-leaders-do-before-building-apps"
          ]
        }
      }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MMMM D, YYYY")
          slug
          excerpt
          isFeatured
          featuredMedia {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                h: "480"
                maxW: 800
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
          author {
            name
            slug
            mainImage {
              gatsbyImageData(
                placeholder: TRACED_SVG
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  fit: "facearea"
                  facepad: 3.0
                  maxW: 200
                }
              )
              url
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
    resources: allDatoCmsResource(
      filter: { isHidden: { eq: false } }
      limit: 12
      sort: { fields: position }
    ) {
      edges {
        node {
          id
          name
          isFeatured
          slug
          previewPageNumber
          description
          isHidden
          price
          payhipProductId
          file {
            url
            fixed(
              height: 200
              imgixParams: {
                h: "200"
                fm: "jpg"
                fit: "crop"
                auto: "compress"
              }
            ) {
              ...GatsbyDatoCmsFixed
            }
          }
          featuredMedia {
            url
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                h: "480"
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
        }
      }
    }
  }
`
